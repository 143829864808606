import React from "react";
import {
  Avatar,
  Button,
  Flex,
  Text,
  Header,
  Grid,
  Box,
  Label,
  Loader,
} from "@fluentui/react-northstar";

import "./NewProfile.scss";
import AvailableTimeSlots from "./AvailableTimeSlots";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import userSvg from "../../../../Assets/images/svg/user.svg";
import { useBooking } from "../../../../context/BookingContext";
import ReviewCard from "./ReviewCard";
import { Row } from "antd";

const NewProfile = (props) => {
  const { t } = useTranslation();

  const { userProfileData: user } = props;
  const { mail, role } = useParams();
  const history = useHistory();
  const { setSelectedTab, reviews, isLoadingReviews } = useBooking();

  const openTeamsChat = () => {
    const teamsChatUrl = `https://teams.microsoft.com/l/chat/0/0?users=${mail}`;

    if (props.isInTeams) {
      // Open the chat inside the Microsoft Teams app
      microsoftTeams.executeDeepLink(teamsChatUrl);
    } else {
      // Redirect to Microsoft Teams in the browser or desktop app
      window.open(teamsChatUrl, "_blank");
    }
  };

  const scrollToSection = () => {
    const section = document.getElementById("review-section");
    section.scrollIntoView({ behavior: "smooth" });
  };

  const askMeAbout = user?.ProfileSpecialities?.length
    ? user?.ProfileSpecialities.map((speciality) => speciality.title)
    : [];
  const translation = t("booking").tutorProfile;

  return (
    <div className="profile-page">
      <div className="header">
        <img
          src="https://images.unsplash.com/photo-1470813740244-df37b8c1edcb?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Background"
          className="header-bg"
        />
        <Flex
          gap="gap.small"
          padding="padding.medium"
          vAlign="center"
          className="profile-info"
        >
          <Avatar
            image={user?.image ? user.image : userSvg}
            name={user?.firstName ? `${user?.firstName}` : "Tutor"}
            size="larger"
            className="profile-avatar"
          />
          <Flex column style={{ alignSelf: "end" }} gap="gap.smaller">
            <Flex column>
              <Text
                content={user?.firstName ? `${user?.firstName}` : "Tutor"}
                className="profile-name"
              />
              <Text content={role} className="profile-title" />
            </Flex>
            <Flex gap="gap.smaller">
              <Button
                content={translation.sendMessage}
                secondary
                onClick={openTeamsChat}
              />
              <Button
                content={`Reviews (${reviews.length})`}
                style={{ color: "#6264a7" }}
                text
                onClick={scrollToSection}
              />
            </Flex>
          </Flex>
        </Flex>
        <div className="available-time-slots-wrapper">
          <AvailableTimeSlots {...props} />
        </div>
      </div>

      <Row
        xxl={12}
        xl={12}
        lg={12}
        md={12}
        sm={24}
        xs={24}
        className="main-content"
      >
        <Flex column className="right-column">
          <div className="available-time-slots-wrapper-main">
            <AvailableTimeSlots {...props} />
          </div>
          <Flex column className="talk-topics" gap="gap.medium">
            <Text content={translation.askMeAbout} className="section-title" />
            {user?.ProfileSpecialities?.length ? (
              <Flex wrap gap="gap.small">
                {askMeAbout.map((topic, index) => (
                  <Label
                    key={index}
                    content={topic}
                    circular
                    className="topic-label"
                  />
                ))}
              </Flex>
            ) : (
              <Flex
                className="empty-data-block"
                vAlign="center"
                hAlign="center"
              >
                {translation.notProvided}
              </Flex>
            )}
          </Flex>

          <Flex column className="skills" gap="gap.medium">
            <Text content={translation.skills} className="section-title" />
            {user?.ProfileSkills?.length ? (
              <Flex wrap gap="gap.small">
                {user?.ProfileSkills.map((skill, index) => (
                  <Label
                    key={index}
                    content={skill.title}
                    circular
                    className="skill-label"
                  />
                ))}
              </Flex>
            ) : (
              <Flex
                className="empty-data-block"
                vAlign="center"
                hAlign="center"
              >
                {translation.noSkills}
              </Flex>
            )}
          </Flex>
          <Flex column className="interests" gap="gap.medium">
            <Text content={translation.interests} className="section-title" />
            {user?.ProfileInterests?.length ? (
              <Flex wrap gap="gap.small">
                {user?.ProfileInterests.map((interest, index) => (
                  <Label
                    key={index}
                    content={interest.title}
                    circular
                    className="interest-label"
                  />
                ))}
              </Flex>
            ) : (
              <Flex
                className="empty-data-block"
                vAlign="center"
                hAlign="center"
              >
                {translation.noInterests}
              </Flex>
            )}
          </Flex>

          <Flex column className="reviews" gap="gap.medium" id="review-section">
            <Flex column gap="gap.small">
              <Text content={translation.reviews} className="section-title" />
              {isLoadingReviews ? (
                <Flex vAlign="center" hAlign="center">
                  <Loader />
                </Flex>
              ) : reviews.length ? (
                reviews.map((review, i) => (
                  <React.Fragment key={`booking-review-${i}`}>
                    <ReviewCard review={review} />
                  </React.Fragment>
                ))
              ) : (
                <Flex hAlign="center">
                  <Text content={translation.noReviews} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Row>
    </div>
  );
};

export default NewProfile;
