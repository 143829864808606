import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";

import "./TutorProfile.scss";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import Header from "../../../../components/Header/Header";
import { useBooking } from "../../../../context/BookingContext";
import Profile from "./Profile";
import NewProfile from "./NewProfile";
import { Breadcrumb, ChevronEndMediumIcon } from "@fluentui/react-northstar";
import { BackTop } from "antd";

const url = process.env.REACT_APP_EP_URL;

const TutorProfile = (props) => {
  const { mail, role } = useParams();
  // const { getTutorProfileData } = useBooking();
  const { t } = props;

  const translation = t("feedback");
  const feedbackTranslation = translation.body.mainPage;

  const [userProfileData, setUserProfileData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 767);

  const history = useHistory();

  const { getReviewsByTutor, getScheduledSlotsByDates } = useBooking();

  const getProfileData = async () => {
    try {
      setIsLoadingData(true);
      const { slug, accessToken } = props.user;
      const { data } = await axios.get(
        `${url}/api/tutor/get-tutor-info/${mail}${slug}&role=${role}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (data) {
        let profileData = {
          ...data,
          skills: data.ProfileSkills,
          interests: data.ProfileInterests,
        };
        setUserProfileData(profileData);
      } else {
        setUserProfileData({});
      }
    } catch (error) {
      NotificationAlert(
        typeof error?.response?.data?.message === "string"
          ? error.response.data.message
          : feedbackTranslation.saveError,
        "error"
      );
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    mail && getReviewsByTutor(mail);
  }, [mail]);

  useEffect(() => {
    mail && getScheduledSlotsByDates(mail);
  }, [mail]);

  return (
    <div>
      <Header
        path="booking"
        user={{ ...props.user }}
        logout={props.logout.bind()}
        accessToken={props.user.accessToken}
        getDb={props.getDb.bind()}
        switchDb={props.switchDb}
        selectedExtension={props.selectedExtension}
        setSelectedExtension={props.setSelectedExtension}
      />
      <Breadcrumb
        aria-label="breadcrumb"
        style={{ marginLeft: "20px", marginTop: "20px" }}
      >
        <Breadcrumb.Item color="#6264a7">
          <Breadcrumb.Link
            color="#6264a7"
            style={{ color: "#6264a7" }}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/booking?tab=Tutors`);
            }}
          >
            Tutors
          </Breadcrumb.Link>
        </Breadcrumb.Item>
        <Breadcrumb.Divider>
          <ChevronEndMediumIcon />
        </Breadcrumb.Divider>
        <Breadcrumb.Item aria-current="page" style={{ color: "#6264a7" }}>
          {userProfileData?.firstName
            ? `${userProfileData?.firstName || ""} ${
                userProfileData?.lastName || ""
              }`
            : "Tutor"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <NewProfile
        userProfileData={userProfileData}
        isMobileView={isMobileView}
        isLoadingData={isLoadingData}
        {...props}
      />
      <BackTop style={{ color: "#6264a7", right: "25px" }} />
    </div>
  );
};
export default withTranslation()(TutorProfile);
